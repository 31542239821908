<template>
  <div class="request-history">
    <div class="page-header">
      <div class="page-header--title">
        <h2 class="title">Historique des conversations</h2>
      </div>
    </div>

    <section class="page-content">
      <div class="page-content-box chat-history" v-if="requests.length">
        <div class="chats-list">
          <div class="chats-list-item"
            v-for="request in requests" :key="request.uuid">
              <a href="#" @click="setViewedRequest(request)">
                <div v-if="request.initiator">
                  <div v-if="request.initiator.firstname && request.initiator.lastname">
                    {{ request.initiator.firstname }} {{ request.initiator.lastname }}
                  </div>
                  <div v-else>
                    {{ request.initiator.email }}
                  </div>
                </div>
                <small>{{ formatDate(request.started_at, 'dd LLLL Y HH:mm') }}</small>
                <div class="messages-count">
                  <b-tooltip :label="request.messages_count + ' messages échangés'"
                      position="is-right">
                      <b-tag type="is-primary is-light">{{ request.messages_count }}</b-tag>
                  </b-tooltip>
                </div>
              </a>
          </div>
        </div>
        <div class="chats-content">
          <div class="chat-header" v-if="requestViewed">
            Discussion avec {{ requestViewed.initiator.email }}, le {{ formatDate(requestViewed.started_at, 'dd LLLL Y \à HH:mm') }}
          </div>
          <div
            class="chat-messages-empty has-text-grey"
            v-if="! requestViewed.messages.length"
          >
            Pas de messages échangés
          </div>
          <div class="chat-messages" v-if="requestViewed.messages.length">
            <div class="chat-message"
              :class="{
                'chat-author-is-user': message.creator.uuid === requestViewed.initiator.uuid,
                'chat-author-is-replier': message.creator.uuid !== requestViewed.initiator.uuid
              }"
              v-for="message in requestViewed.messages" :key="message.id"
            >
              <div class="chat-author">
                <div class="chat-author-avatar">
                  {{ formatAvatar(message.creator.firstname) + formatAvatar(message.creator.lastname) }}
                </div>
                <div class="chat-author-name" v-if="message.creator.firstname && message.creator.lastname">
                   {{ message.creator.firstname }} {{ message.creator.lastname }}
                </div>
                <div class="chat-author-name" v-else>
                   {{ message.creator.email }}
                </div>
              </div>
              <div class="chat-message-message card">
                <div class="chat-message-text">
                  {{ message.content }}
                </div>
                <div class="chat-message-datetime">
                  {{ formatDate(message.created_at, 'HH:mm:ss') }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { parseISO, format } from 'date-fns'
import fr from 'date-fns/locale/fr'
import { http } from '@/services/api.service'

export default {
  data () {
    return {
      requests: [],
      requestViewed: {},
      loading: false
    }
  },
  mounted () {
    this.loadRequests()
  },
  methods: {
    loadRequests () {
      this.loading = true
      http.get(`/request/history`).then(res => {
        this.loading = false
        this.requests = res.data
        if (this.requests.length) {
          this.requestViewed = this.requests[0]
        }
      }).catch(err => {
        console.log(err)
      })
    },
    formatDate (date, dateFormat) {
      return format(parseISO(date), dateFormat, {
        locale: fr
      })
    },
    setViewedRequest (request) {
      this.requestViewed = request
    },
    formatAvatar (word) {
      if (!word) return ''
      return word.charAt(0).toUpperCase()
    }
  }
}
</script>

<style scoped lang="scss">
.chat-history {
  width: 100%;
  display: flex;
  align-items: flex-start;
  height: 100%;
}
.chats-list {
  border-right: 1px solid #e5e5e5;
  width: 250px;
  height: 100%;

  .chats-list-item {
    background-color: white;
    border-bottom: 1px solid #e5e5e5;
    line-height: 20px;
    position: relative;

    &:last-child {
      border-bottom: 0;
    }

    .messages-count {
      position: absolute;
      top: 10px;
      right: 10px;

      .tag {
        background-color: #CBD8EA;
        color: #445877;
        border-radius: 50px;
      }
    }

    a {
      display: block;
      padding: 10px 35px 10px 10px;
      border-right: 4px solid transparent;

      &:hover {
        border-right: 4px solid #3b82f6;
      }
    }
  }
}
.chat-messages-empty {
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
}
.chat-messages {
  display: flex;
  flex-direction: column;
}
.chat-message {
  padding: 20px;
  max-width: 70%;

  &.chat-author-is-replier {
    align-self: flex-end;
    .chat-message-message {
      margin-left: auto;
    }
  }

  .chat-message-meta {

  }
  .chat-author {
    display: flex;
  }
  .chat-author-avatar {
    height: 30px;
    width: 30px;
    font-size: 12px;
    border-radius: 50px;
    background-color: #3b82f6;
    color: #fff;
    text-align: center;
    line-height: 30px;
  }
  .chat-author-name {
    line-height: 30px;
    margin-left: 6px;
  }
  .chat-message-message {
    background-color: #F2F2F2;
    border-radius: 10px;
    padding: 10px 15px;
    margin-top: 6px;
    width: fit-content;
  }
  .chat-message-datetime {
    text-align: right;
    font-size: 11px;
  }
}
.chats-content {
  width: 100%;
  height: 100%;
}
.chat-header {
  padding: 20px;
  border-bottom: 1px solid #e5e5e5;
}
</style>
